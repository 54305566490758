const getIn = require('./lib/getIn');

class Translator {
    constructor() {
        this._locale = 'fr';
        this._translations = { fr: {} };
    }

    setLocale(locale) {
        this._locale = locale.toLowerCase();

        if (!this._translations[this._locale]) {
            this._translations[this._locale] = {};
        }
    }

    load(locale, translations) {
        this._translations[locale.toLowerCase()] = translations;
    }

    get(path, params, fallbackPath = false) {
        const translation = getIn(this._translations[this._locale], path.split('.'));

        if (typeof translation !== 'string') {
            return fallbackPath ? this.get(fallbackPath) : path;
        }

        return this._replacePlaceholders(translation, params);
    }

    _replacePlaceholders(translation, params = {}) {
        return Object.keys(params).reduce(
            (translation, paramName) =>
                translation.replace(
                    new RegExp(`\\$${paramName}`, 'g'),
                    params[paramName] || '',
                ),
            translation,
        );
    }
}

module.exports = Translator;
