import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './flexboxgrid.min.css';
import { hotjar } from 'react-hotjar';
import { envHasHotjar, getEnvValue, envHasGA } from 'utils';
import ReactGA from 'react-ga';

if (envHasHotjar()) {
    const { hjid, hjsv } = getEnvValue('hotjar');
    hotjar.initialize(hjid, hjsv);
}
if (envHasGA()) {
    ReactGA.initialize(getEnvValue('googleAnalyticId'));
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);
