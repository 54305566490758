import useWindowSize from '../hooks/useWindowSize';
import GoogleMapReact from 'google-map-react';
import config from 'config';
import Orientation from '../../assets/svgs/orientation';
import { useField, useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import whiteDot from '../../assets/images/whiteDot.png';
import theme from 'theme';

const mapConfig = {
    map: {
        center: { lat: 46.711, lng: 1.171 },
        zoom: 5,
    },
};
let markers = [];
export default function Map(props) {
    const [, , orientationHelpers] = useField('orientation');
    const [, , roofAreaHelpers] = useField('roofArea');
    const { values } = useFormikContext();
    const { width } = useWindowSize();
    const styles = makeStyles(width);
    const polygon = useRef();
    const events = [];

    useEffect(() => {
        return () => {
            events.map(event => props.mapInstanceRef.removeListener(event));
        };
    }, []);

    useEffect(() => {
        if (props.mapIsInit && props.step === 1) {
            deletePoly();
            deleteMarkers();
            props.polygonPos.current = undefined;
            orientationHelpers.setValue('');
            roofAreaHelpers.setValue('');
            moveToPosition(props.googlePlace.current);
        }
    }, [props.step]);

    useEffect(() => {
        if (
            props.mapIsInit &&
            values.orientation &&
            values.roofAreaSelection === 'auto'
        ) {
            centerMapOnPolygon();
        }
    }, [values.orientation]);

    useEffect(() => {
        if (
            props.mapIsInit &&
            values.address &&
            values.roofAreaSelection === 'auto'
        ) {
            if (props.step === 2) {
                const location = props.mapInstanceRef.current.center;
                props.googlePlace.current = location;
                const defaultSize = 0.0001;
                const lat = location.lat();
                const lng = location.lng();

                props.polygonPos.current = {
                    1: { lat: lat + defaultSize, lng: lng + defaultSize },
                    2: { lat: lat - defaultSize, lng: lng + defaultSize },
                    3: { lat: lat - defaultSize, lng: lng - defaultSize },
                    4: { lat: lat + defaultSize, lng: lng - defaultSize },
                };
                drawMarkers(props.polygonPos.current);
                drawPoly(props.polygonPos.current);
            } else if (props.step > 2) {
                drawPoly(props.polygonPos.current);
                centerMapOnPolygon();
                deleteMarkers();
            }
        }
    }, [props.step, values.address, props.mapIsInit]);

    const deleteMarkers = () => {
        markers.map(marker => marker.setMap(null));
    };

    const deletePoly = () => {
        if (polygon.current) {
            polygon.current.setMap(null);
        }
    };

    const initMap = ({ map, maps }) => {
        props.mapInstanceRef.current = map;
        props.mapApiRef.current = maps;
        map.setTilt(0);
        map.mapTypeId = 'satellite';
        props.setMapIsInit(true);
        if (props.googlePlace.current) {
            moveToPosition(props.googlePlace.current);
        }
        if (props.polygonPos.current) {
            drawPoly(props.polygonPos.current);
            centerMapOnPolygon();
        }
    };

    const moveToPosition = (pos, zoom = 19) => {
        props.mapInstanceRef.current.setCenter(pos);
        props.mapInstanceRef.current.setZoom(zoom);
    };

    const centerMapOnPolygon = () => {
        if (props.polygonPos.current) {
            let bounds = new props.mapApiRef.current.LatLngBounds();
            for (let i = 1; i < 4; i++) {
                bounds.extend(props.polygonPos.current[i]);
            }
            moveToPosition(bounds.getCenter());
        }
    };

    const drawMarkers = pos => {
        const icon = {
            url: whiteDot,
            anchor: new props.mapApiRef.current.Point(7, 7),
            scaledSize: new props.mapApiRef.current.Size(12, 12),
        };

        for (let i = 1; i <= 4; i++) {
            const marker = new props.mapApiRef.current.Marker({
                position: pos[i],
                map: props.mapInstanceRef.current,
                draggable: true,
                icon,
            });
            markers.push(marker);
            const drag = props.mapApiRef.current.event.addListener(
                marker,
                'drag',
                () => {
                    pos[i] = marker.position;
                    drawPoly(pos);
                },
            );
            const dragend = props.mapApiRef.current.event.addListener(
                marker,
                'dragend',
                () => {
                    computeArea();
                },
            );
            events.push(drag, dragend);
        }
    };

    const drawPoly = pos => {
        if (polygon.current) {
            deletePoly();
        }
        polygon.current = new props.mapApiRef.current.Polygon({
            strokeColor: theme.colors.primary,
            strokeWeight: 2,
            fillColor: theme.colors.primary,
            paths: [pos[1], pos[2], pos[3], pos[4]],
        });
        polygon.current.setMap(props.mapInstanceRef.current);
    };

    const computeArea = () => {
        const area = props.mapApiRef.current.geometry.spherical.computeArea(
            polygon.current.getPath(),
        );
        roofAreaHelpers.setValue(Math.round(area));
    };

    function createMapOptions(maps) {
        return {
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_RIGHT,
                mapTypeIds: ['satellite', 'roadmap'],
            },
            mapTypeControl: true,
            rotateControl: false,
            fullscreenControl: false,
            controlSize: 25,
        };
    }

    return (
        // Important! Always set the container height explicitly
        <div style={styles.container}>
            {values.orientation !== '' && (
                <Orientation
                    style={styles.orientation}
                    orientation={values.orientation}
                />
            )}
            <GoogleMapReact
                options={createMapOptions}
                bootstrapURLKeys={config.apiGoogle}
                defaultCenter={mapConfig.map.center}
                defaultZoom={mapConfig.map.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={initMap}
            />
        </div>
    );
}

const makeStyles = width => ({
    container: {
        position: 'relative',
        height: 350,
        minWidth: width > 580 ? 580 : width,
    },
    orientation: {
        position: 'absolute',
        zIndex: 10,
        opacity: 0.5,
        height: 351,
        left: 0,
        pointerEvents: 'none',
    },
});
