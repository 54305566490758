import React from 'react';
import Increment from 'components/Increment/index';

export default function ContainerLarge(props) {
    return (
        <div style={styles.checkboxContainer}>
            {props.data.map(item => (
                <Increment key={item} name={item} size={props.itemSize} />
            ))}
        </div>
    );
}

const styles = {
    checkboxContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridTemplateRows: 'repeat(3, auto)',
        gridRowGap: 6,
        width: '100%',
    },
};
