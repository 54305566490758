import t from 'utils/Translator';
import { Field, useField } from 'formik';
import CheckboxSVG from '../../../../data/assets/svgs/Checkbox';

export default function DunnoCheckbox({ name }) {
    const [field] = useField({ name, type: 'checkbox' });
    return (
        <div style={styles.container}>
            <span style={{ marginRight: 5 }}>
                {t.get('placeholders.dunno')}
            </span>
            <label>
                <Field
                    name={name}
                    type="checkbox"
                    style={{ display: 'none' }}
                />
                <CheckboxSVG checked={field.checked} />
            </label>
        </div>
    );
}

const styles = {
    container: {
        width: '100%',
        fontSize: 11,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: 5,
    },
};
