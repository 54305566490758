import React, { useEffect, useRef } from 'react';
import theme from 'theme';

export default function Icon(props) {
    let ImportedIconRef = useRef(null);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        const importIcon = async () => {
            try {
                ImportedIconRef.current = (
                    await import(
                        `!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/svgs/${props.SVGName}.svg`
                    )
                ).default; // https://stackoverflow.com/a/61472427
            } catch (err) {
                console.log('SVG not found');
            } finally {
                setLoading(false);
            }
        };
        importIcon();
    }, [props.SVGName]);

    const fill = () => {
        if (props.fill) {
            return props.fill;
        } else {
            return props.selected
                ? theme.colors.primary
                : theme.colors.form.gradient03;
        }
    };

    if (!loading && ImportedIconRef.current) {
        const { current: ImportedIcon } = ImportedIconRef;
        return <ImportedIcon fill={fill()} height="100%" width="100%" />;
    }
    return null;
}

// import { ReactComponent as AirAirIcon } from '../assets/svgs/airConditioner.svg';
