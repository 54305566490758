import React from 'react';
import Checkbox from 'components/Checkbox/index';

export default function CheckboxContainer(props) {
    return (
        <div style={styles.checkboxContainer}>
            {props.data.map(item => (
                <Checkbox
                    value={item}
                    key={item}
                    name={props.name}
                    size={props.itemSize}
                />
            ))}
        </div>
    );
}

const styles = {
    checkboxContainer: {
        display: 'flex',
    },
};
