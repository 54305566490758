import theme from 'theme';
import { ReactComponent as Mail } from '../../../data/assets/svgs/mail.svg';
import EstimationTable from './components/EstimationTable';
import t from 'utils/Translator';
import Info from './components/Info';
import { getEnvValue } from 'utils';

export default function Step4(props) {
    const infos = ['economic', 'assets', 'easy', 'independence', 'envFriendly'];

    return (
        <div style={styles.container}>
            <EstimationTable estimation={props.estimation} />
            <div style={styles.header} className="col-xs-12">
                <div style={{ display: 'flex' }}>
                    <Mail style={styles.icon} fill={theme.colors.primary} />
                    <div style={styles.content}>
                        &nbsp;&nbsp;{t.get('titles.mail.1')}
                        <br />&nbsp;&nbsp;{t.get('titles.mail.spam')}
                    </div>
                </div>
            </div>
            <div style={styles.infoContainer} className="row">
                {infos.map((info, index) => (
                    <Info
                        isEven={index % 2 !== 0}
                        key={info}
                        SVGName={info}
                        title={t.get(`titles.${info}`)}
                        content={t.get(`labels.${info}`)}
                        className=" col-md-2"
                    />
                ))}
            </div>
        </div>
    );
}

const styles = {
    container: {
        margin: 0,
    },
    header: {
        backgroundColor: theme.colors.form.gradient01,
        fontSize: 21,
        padding: 20,
        display: 'flex'
    },
    icon: {
        width: 40,
        height: 40,
        marginBottom: 10,
    },
    mail02: {
        marginTop: 30,
        fontSize: 14,
        lineHeight: 2,
    },
    infoContainer: {
        margin: 0,
    },
};
