import config from 'config';

const init = {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
        'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
};

const http = {
    post: (url, form) => {
        return fetch(url, {
            ...init,
            method: 'POST',
            body: JSON.stringify(form),
        })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    return response.data;
                }
                throw response;
            })
            .catch(e => {
                throw e;
            });
    },
};

// estimation de la facture d'electricité
export const fetchBill = form => {
    return http.post(config.fetchBill, form);
};
// recap avant envoi du formulaire
export const fetchEstimation = form => {
    return http.post(config.fetchEstimation, form);
};

export const sendForm = form => {
    return http.post(config.sendFormUrl, form);
};
