import config from 'config';

export const initialValues = {
    buildingType: '', // 'individual', 'collective'
    situation: '', // 'owner', 'residence', 'hotel', 'trade'
    autoConsumption: '', // '', 'full', 'partial', 'resale'
    year: new Date().getFullYear(),
    livingArea: '',
    resident: '',
    postalCode: '',
    address: '',
    roofArea: '',
    provider: '', // 'edf", 'other'
    heating: '', // ['electricHeater',  'airConditioner', 'pac','fossilFuel'],
    hotWater: '', //  'thermodynamic', 'waterTank', 'fossilFuel', 'other',
    electricPanels: '',
    bill: '',
    orientation: '', // 'E', 'SE', 'S', 'SO', 'O'
    roofAreaSelection: 'auto', // 'auto', 'manual
    floorArea: '',

    fridge: 0,
    tv: 0,
    washingMachine: 0,
    dishWasher: 0,
    dryer: 0,
    swimmingPool: 0,
    swimmingPoolPac: 0,
    electricCar: 0,

    firstName: '',
    lastName: '',
    phone: '',
    email: '',

    electricPanelsCheckbox: false,
    billCheckbox: false,

    env: config.env,
    rgbPDF: config.rgbPDF
};
