import theme from 'theme';
import Icon from 'components/Icon';

export default function IconContainer(props) {
    const styles = makeStyles(props);

    return (
        <div
            style={{
                ...styles.container,
                ...(props.selected ? styles.containerSelected : {}),
            }}
        >
            <Icon selected={props.selected} SVGName={props.SVGName} />
        </div>
    );
}

const makeStyles = props => {
    return {
        container: {
            height: height(props.size),
            width: width(props.size),
            minHeight: height(props.size),
            minWidth: width(props.size),
            borderRadius: 3,
            border: props.noBorder
                ? 'none'
                : `1px solid ${theme.colors.form.gradient03}`,
            boxSizing: 'border-box',
            padding: 10,
            pointerEvents: 'none',
        },
        containerSelected: {
            transition: 'border 0.05s',
            padding: 8,
            border: `2px solid ${theme.colors.primary}`,
            boxShadow: '2px 2px 5px 0px rgba(189,179,189,1)',
            backgroundColor: theme.colors.white,
        },
    };
};

const width = size => {
    switch (size) {
        case 'large':
            return 90;
        case 'small':
            return 60;
        default:
            return 70;
    }
};

const height = size => {
    switch (size) {
        case 'small':
            return 60;
        default:
            return 70;
    }
};
