import t from 'utils/Translator';
import Map from 'components/Map';
import { useState } from 'react';
import ButtonComp from 'components/ButtonComp';
import RadioContainer from 'components/Radio/RadioContainer';
import { useFormikContext } from 'formik';
import roofingBad from './../../../data/assets/images/roofingBad.png';
import roofingGood from './../../../data/assets/images/roofingGood.png';

export default function Auto(props) {
    const { values } = useFormikContext();
    const [step, setStep] = useState(props.polygonPos.current ? 3 : 1);

    const renderButtons = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: 20,
                }}
            >
                {!(step === 2 && values.roofArea === '') && step < 3 && (
                    <ButtonComp
                        label={t.get('placeholders.next')}
                        onClick={() => setStep(step + 1)}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <div
                style={styles.tutorialContainer}
                className="col-xs-12 col-md-5"
            >
                <div>
                    {step > 1 && (
                        <ButtonComp
                            color="orange"
                            label={t.get('placeholders.reset')}
                            onClick={() => setStep(1)}
                        />
                    )}
                </div>
                <br />
                <div style={styles.tutorial01}>1. {t.get('tutoRoofing.1')}</div>
                {step > 1 && (
                    <>
                        <div style={styles.tutorial02}>
                            2. {t.get('tutoRoofing.2')}
                        </div>
                        <div style={styles.tutorial02b}>
                            {t.get('tutoRoofing.2b')}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                            }}
                        >
                            <img
                                src={roofingGood}
                                alt="roofingGood"
                                width={150}
                            />
                            <img
                                src={roofingBad}
                                alt="roofingBad"
                                width={150}
                            />
                        </div>
                    </>
                )}
                {step > 2 && (
                    <>
                        <div style={styles.tutorial03}>
                            3. {t.get('tutoRoofing.3')}
                        </div>
                        <RadioContainer
                            noIcon
                            data={['W', 'SW', 'S', 'SE', 'E']}
                            name="orientation"
                        />
                    </>
                )}
                {renderButtons()}
            </div>
            <div style={styles.mapContainer} className="col-xs-12 col-md-7">
                <Map
                    mapInstanceRef={props.mapInstanceRef}
                    mapApiRef={props.mapApiRef}
                    setMapIsInit={props.setMapIsInit}
                    mapIsInit={props.mapIsInit}
                    googlePlace={props.googlePlace}
                    polygonPos={props.polygonPos}
                    step={step}
                />
            </div>
        </>
    );
}

const styles = {
    tutorialContainer: {
        padding: '0 30px',
        marginTop: 12,
        textAlign: 'left',
    },
    tutorial01: {
        marginBottom: 20,
    },
    tutorial02: {
        marginBottom: 5,
    },
    tutorial02b: {
        marginBottom: 5,
        fontSize: 12,
    },
    tutorial03: {
        margin: '10px 0',
    },
    mapContainer: {
        marginTop: 12,
    },
};
