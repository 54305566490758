import { useEffect } from 'react';
import t from 'utils/Translator';
import theme from 'theme';
import RadioContainer from 'components/Radio/RadioContainer';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import Manual from './Manual';
import Auto from './Auto';
import ButtonComp from 'components/ButtonComp';

const pitches = {
    0: 1,
    15: 1.3,
    30: 1.6,
    45: 2,
};

export default function Roofing(props) {
    const { values } = useFormikContext();
    const styles = makeStyles(values);
    const [, , helpers] = useField('roofArea');

    useEffect(() => {
        if (values.floorArea && values.roofPitch) {
            helpers.setValue(
                Math.round(values.floorArea / pitches[values.roofPitch]),
            );
        }
    }, [values.floorArea, values.roofPitch]);

    return (
        <>
            <div style={styles.container} className="row center-xs">
                <div style={styles.title} className="col-xs-12">
                    {t.get('titles.roofing')}
                </div>
                <div style={styles.roofAreaContainer} className="col-xs-12">
                    <div style={styles.roofArea} />
                    <RadioContainer
                        data={['auto', 'manual']}
                        name="roofAreaSelection"
                    />
                    <div style={styles.roofArea}>
                        {`${t.get('labels.approxRoofArea')} : `}
                        <span style={styles.span}>{values.roofArea}m²</span>
                    </div>
                </div>
                <div className="col-xs-12 row">
                    {values.roofAreaSelection === 'auto' ? (
                        <Auto {...props} />
                    ) : (
                        <Manual />
                    )}
                </div>
                <div style={styles.buttonContainer}>
                    <ButtonComp
                        style={
                            values.roofArea && values.orientation
                                ? { display: 'flex' }
                                : { display: 'none' }
                        }
                        label={t.get('placeholders.next')}
                        onClick={props.nextStep}
                    />
                </div>
            </div>
        </>
    );
}

const makeStyles = values => ({
    container: {
        ...theme.active(values.roofArea && values.orientation),
        ...theme.disabling(values.postalCode && values.postalCode.length >= 5 && values.address),
        backgroundColor: theme.colors.form.gradient02,
        padding: '0 0 30px 0',
        marginRight: 0,
    },
    title: {
        margin: '20px 0 10px 0',
    },
    span: {
        color: theme.colors.primary,
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10,
    },
    roofAreaContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
    },
    roofArea: { width: '100%', padding: '0 5px 15px 50px' },
});
