import React from 'react';
import IconContainer from '../IconContainer';
import { Field, useField } from 'formik';
import t from 'utils/Translator';
import theme from 'theme';

function Increment({ name, size }) {
    const [field, meta, helpers] = useField(name);
    const value = field.value;
    const styles = makeStyles(value);

    const increment = () => {
        if (value < 3) {
            helpers.setValue(value + 1);
        }
    };
    const decrement = () => {
        if (value > 0) {
            helpers.setValue(value - 1);
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <IconContainer
                    selected={field.value}
                    SVGName={name}
                    size={size}
                />
                <div style={styles.incrementContainer}>
                    <div style={styles.incrementButton} onClick={increment}>
                        +
                    </div>
                    <div style={styles.incrementLabel}>
                        {`${value}${value === 3 ? '+' : ''}`}
                    </div>
                    <div style={styles.incrementButton} onClick={decrement}>
                        -
                    </div>
                </div>
            </div>

            <div style={styles.label}>{t.get(`labels.${name}`)}</div>
        </div>
    );
}

const makeStyles = value => {
    return {
        container: {
            height: '100%',
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
        },
        label: {
            paddingTop: 4,
            height: '100%',
            width: '90%',
            fontSize: theme.font.size.S,
            textAlignLast: 'center',
            textAlign: 'center',
        },
        incrementContainer: {
            border: `1px solid ${theme.colors.form.gradient03}`,
            borderRadius: 3,
            display: 'flex',
            flexDirection: 'column',
            width: 20,
            justifyContent: 'space-around',
            alignItems: 'center',
            backgroundColor: theme.colors.white,
            marginLeft: 1,
        },
        incrementLabel: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: theme.font.size.S,
            flexGrow: 1,
            width: '100%',
        },
        incrementButton: {
            color: theme.colors.primary,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            width: '100%',
            userSelect: 'none',
        },
    };
};

export default Increment;
