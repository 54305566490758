import theme from 'config/theme';

export default function Orientation(props) {
    const renderPath = () => {
        switch (props.orientation) {
            case 'E':
                return (
                    <path
                        fill={theme.colors.primary}
                        d="M114.78 48.08V61.7l66.55 23.63V24.44l-66.55 23.64zM66.67 24.44v60.89L66.67 61.7V48.08z"
                    />
                );
            case 'SE':
                return (
                    <path
                        fill={theme.colors.primary}
                        d="M97.42 67.11l23.42 42.45h60.49V85.33L114.78 61.7l-17.36 5.41z"
                    />
                );
            case 'S':
                return (
                    <path
                        fill={theme.colors.primary}
                        d="M97.53 67.11H83.92l-23.64 42.45h60.89L97.53 67.11z"
                    />
                );
            case 'SW':
                return (
                    <path
                        fill={theme.colors.primary}
                        d="M66.67 61.7L.11 85.33V54.89H0v54.67h60.49l23.43-42.45-17.25-5.41z"
                    />
                );
            case 'W':
                return (
                    <path
                        fill={theme.colors.primary}
                        d="M114.78 23.64zM66.67 48.08L.11 24.44v60.89L66.67 61.7V48.08z"
                    />
                );
            default:
                return null;
        }
    };

    return (
        <svg viewBox="0 0 181.33 109.56" {...props}>
            {renderPath()}
        </svg>
    );
}
