import React from 'react';
import { Field, useField } from 'formik';
import theme from 'theme';

function RadioNumbersComp({ name, onClick, value, wideIcon }) {
    const [field] = useField({ name, value, type: 'radio' });
    const styles = makeStyle(wideIcon);
    return (
        <div style={styles.container}>
            <label>
                <Field
                    onClick={onClick}
                    name={name}
                    value={value}
                    type="radio"
                    style={{ display: 'none' }}
                />
                <div
                    style={{
                        ...styles.radio,
                        ...(field.checked ? styles.radioSelected : {}),
                    }}
                >
                    {wideIcon ? `${value}+` : value}
                </div>
            </label>
        </div>
    );
}

const makeStyle = wideIcon => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        radio: {
            fontSize: theme.font.size.L,
            color: theme.colors.form.gradient03,
            display: 'flex',
            border: `1px solid ${theme.colors.form.gradient03}`,
            height: 45,
            width: wideIcon ? 100 : 45,
            flexGrow: wideIcon ? 31 : 1,
            borderRadius: 50,
            justifyContent: 'center',
            alignItems: 'center',
        },
        radioSelected: {
            color: theme.colors.primary,
            border: `1px solid ${theme.colors.primary}`,
            boxShadow: '2px 2px 5px 0px rgba(189,179,189,1)',
            backgroundColor: theme.colors.white,
        },
    };
};

export default RadioNumbersComp;
