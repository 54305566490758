import t from 'utils/Translator';
import RadioContainer from 'components/Radio/RadioContainer';
import InputComp from 'components/input/inputComp';

export default function Manual() {
    return (
        <>
            <div className="col-xs-12 col-md-6" style={styles.containers}>
                <div style={styles.title}>{t.get('titles.orientation')}</div>
                <RadioContainer
                    noIcon
                    data={['W', 'SW', 'S', 'SE', 'E']}
                    name="orientation"
                />
            </div>

            <div className="col-xs-12 col-md-6" style={styles.containers}>
                <div>
                    <div style={styles.title}>{t.get('titles.roofPitch')}</div>
                    <RadioContainer
                        data={['0', '15', '30', '45']}
                        name="roofPitch"
                    />
                </div>
                <div>
                    <div style={styles.title}>{t.get('titles.floorArea')}</div>
                    <InputComp
                        name="floorArea"
                        placeholder={t.get('placeholders.floorArea')}
                        min="0"
                    />
                </div>
            </div>
        </>
    );
}

const styles = {
    title: {
        margin: '20px 0 10px 0',
    },
    containers: {
        padding: '0 5%',
    },
};
