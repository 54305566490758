import React from 'react';
import t from 'utils/Translator';
import { ReactComponent as BrandLogo } from '../../assets/logos/brand_logo.svg';
import brandBanner from '../../assets/banners/brand_banner.jpg';
import config from 'config';

export default function Header() {
    return (
        <div style={styles.container}>
            <div style={styles.logoContainer}>
                {config.header.logo.href
                    ? <a href={config.header.logo.href} target="_blank"><BrandLogo style={styles.logo} /></a>
                    : <BrandLogo style={styles.logo} />
                }
                <div style={styles.text}>{config.header.title}</div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        backgroundImage: `url(${brandBanner})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: config.header.backgroundPosition,
        backgroundSize: config.header.backgroundSize ? config.header.backgroundSize : 'cover',
        display: 'flex',
        alignItems: 'flex-start',
    },
    logoContainer: {
        padding: config.header.logoContainerPadding,
        display: 'flex',
        alignItems: 'center',
        color: 'white',
    },
    logo: config.header.logo,
    text: {
        paddingLeft: 10,
    },
};
