import React from 'react';
import Form from './scenes/Form';
import Header from './data/common/components/Header';

export default function App() {
    return (
        <div style={styles.container}>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <Form />
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        marginBottom: 100,
    },
};
