import { useState } from 'react';
import t from 'utils/Translator';
import { ErrorMessage, Field, useField } from 'formik';
import theme from 'theme';

function Input({ name, type }) {
    const [field] = useField(name);
    const [focus, setFocus] = useState(false);
    const styles = makeStyle();
    return (
        <div style={styles.container}>
            <label style={styles.label}>{t.get(`labels.${name}`)}</label>
            <Field
                onFocus={() => setFocus(true)}
                onBlur={e => {
                    field.onBlur(e);
                    setFocus(false);
                }}
                style={{ ...styles.field, ...(focus ? styles.fieldFocus : {}) }}
                type={type}
                name={name}
                placeholder={t.get(`labels.${name}`)}
            />
            <ErrorMessage style={styles.error} name={name} component="div" />
        </div>
    );
}

const makeStyle = focus => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 10,
            width: 350,
        },
        label: {
            fontWeight: 'bold',
            textAlign: 'left',
            width: '100%',
            marginBottom: 5,
        },
        field: {
            backgroundColor: theme.colors.form.gradient01,
            boxSizing: 'border-box',
            outline: 'none',
            border: 'none',
            borderBottom: `1px solid ${theme.colors.form.gradient03}`,
            padding: 5,
            fontSize: theme.font.size.M,
        },
        fieldFocus: {
            outline: 'none',
            border: 'none',
            borderBottom: `2px solid ${theme.colors.primary}`,
        },
        error: {
            marginTop: 5,
            color: theme.colors.error,
            fontSize: 12,
        },
    };
};

export default Input;
