import theme from 'theme';
import Icon from 'components/Icon';

export default function Info(props) {
    const styles = makeStyles(props);
    return (
        <div style={styles.container}>
            <div style={{ height: 50, width: 60, alignSelf: 'flex-start' }}>
                <Icon
                    style={styles.icon}
                    fill={
                        props.isEven ? theme.colors.black : theme.colors.white
                    }
                    SVGName={props.SVGName}
                />
            </div>
            <div style={styles.title}>{props.title}</div>
            <div style={styles.content}>{props.content}</div>
        </div>
    );
}

const makeStyles = props => {
    return {
        container: {
            backgroundColor: props.isEven
                ? theme.colors.form.gradient01
                : theme.colors.primary,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: 170,
            height: 250,
            padding: 10,
            color: props.isEven ? theme.colors.black : theme.colors.white,
            borderRadius: 10,
            margin: '0 4px',
            boxShadow: '7px 8px 10px -5px #9E9E9E',
            marginTop: 20,
        },
        icon: {
            width: 40,
            height: 40,
        },
        title: { fontSize: 19, margin: '10px 0', alignSelf: 'flex-start' },
        content: { fontSize: 13, textAlign: 'left' },
    };
};
