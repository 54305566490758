import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import theme from 'theme';
import t from 'utils/Translator';

const defaultCoords = { lat: 46.711, lng: 1.171 };

export default function AddressInput(props) {
    const [focus, setFocus] = useState(false);
    const [address, , addressHelpers] = useField('address');
    const [, , roofAreaHelpers] = useField('roofArea');
    const searchInput = useRef();
    let searchBox;

    useEffect(() => {
        if (!address.value) {
            clearSearchBox();
        }
    }, [address.value]);

    useEffect(() => {
        if (props.mapIsInit && !searchInput.current?.value) {
            props.moveToCurrentPosition(defaultCoords, 5);
        }
    }, [props.mapIsInit, searchInput.current?.value]);

    useEffect(() => {
        if (!props.mapIsInit) return;
        searchBox = new props.mapApiRef.current.places.SearchBox(
            searchInput.current,
        );
        searchBox.addListener('places_changed', onPlacesChanged);
        searchBox.bindTo('bounds', props.mapInstanceRef.current);
        if (address.value) {
            searchInput.current.value = address.value;
        }
        return () => {
            props.mapApiRef.current.event.clearInstanceListeners(searchInput);
        };
    }, [props.mapIsInit]);

    const onPlacesChanged = () => {
        const selected = searchBox.getPlaces();
        const { 0: place } = selected;
        if (place) {
            props.googlePlace.current = place.geometry.location;
            roofAreaHelpers.setValue('');
            addressHelpers.setValue(place.formatted_address);
            props.moveToCurrentPosition(place.geometry.location);
        }
    };

    const handleKeyPress = e => {
        if (e.code === 'Enter') {
            if (!searchInput.current?.value) {
                props.moveToCurrentPosition(defaultCoords, 5);
            }
        }
    };

    const clearSearchBox = () => (searchInput.current.value = '');

    return (
        <div style={styles.container}>
            <input
                style={{
                    ...styles.field,
                    ...(focus ? styles.fieldFocus : {}),
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onKeyPress={handleKeyPress}
                ref={searchInput}
                type="text"
                placeholder={t.get('placeholders.address')}
            />
        </div>
    );
}

const styles = {
    container: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
    },
    field: {
        width: '70%',
        backgroundColor: 'transparent',
        outline: 'none',
        border: 'none',
        borderBottom: `1px solid ${theme.colors.form.gradient03}`,
        padding: 5,
        fontSize: theme.font.size.M,
        textAlign: 'center',
    },
    fieldFocus: {
        outline: 'none',
        border: 'none',
        borderBottom: `2px solid ${theme.colors.primary}`,
    },
};
