import config from 'config';

const theme = {
    colors: {
        primary: config.color,
        form: {
            gradient01: '#F7F7F7',
            gradient02: '#E7E7E7',
            gradient03: '#B1B1B1',
        },
        white: 'white',
        black: 'black',
        background: 'white',
        error: 'red',
        success: 'green',
        warning: 'yellow',
    },
    font: {
        size: {
            S: 12,
            M: 14,
            L: 20,
        },
    },
    disabling: value =>
        !value
            ? { opacity: 0, zIndex: -1 }
            : { transition: 'opacity 0.5s', opacity: 1 },
    active: value =>
        !value
            ? {
                  transition: 'border 0.5s',
                border: `1px solid ${config.color}`,
                  borderRadius: 3,
              }
            : {},
};

export default theme;
