import React from 'react';
import theme from 'theme';

export default function ButtonComp(props) {
    const styles = makeStyles(props);
    return (
        <button
            //disabled={props.disabled}
            type="button"
            style={{ ...styles.container, ...(props.style ? props.style : {}) }}
            onClick={props.onClick}
        >
            {props.label}
        </button>
    );
}

const makeStyles = props => {
    return {
        container: {
            padding: '8px 14px',
            fontSize: theme.font.size.M,
            backgroundColor: props.color ? props.color : theme.colors.primary,
            color: theme.colors.white,
            border: 'none',
            outline: 'none',
            borderRadius: 3,
            opacity: props.disabled ? 0.5 : 1,
        },
    };
};
