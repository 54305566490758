import React from 'react';
import IconContainer from '../IconContainer';
import { Field, useField } from 'formik';
import t from 'utils/Translator';
import theme from 'theme';

function Checkbox({ name, value, size }) {
    const [field] = useField({ name, value, type: 'checkbox' });

    return (
        <div style={styles.container}>
            <label>
                <Field
                    name={name}
                    value={value}
                    type="checkbox"
                    style={{ display: 'none' }}
                />
                <IconContainer
                    selected={field.checked}
                    SVGName={value}
                    size={size}
                />
            </label>
            <div style={styles.label}>{t.get(`labels.${value}`)}</div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        margin: '0 2px',
    },
    label: {
        paddingTop: 4,
        height: '100%',
        width: '90%',
        fontSize: theme.font.size.S,
        textAlignLast: 'center',
        textAlign: 'center',
    },
};

export default Checkbox;
