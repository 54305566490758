import { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import t from 'utils/Translator';
import theme from 'theme';

export default function InputComp(props) {
    const [focus, setFocus] = useState(false);
    const { touched, errors } = useFormikContext();
    const styles = makeStyles(props, touched, errors, focus);

    return (
        <div style={styles.container}>
            {props.withClick ? <div style={{ width: '100%' }} /> : null}
            <Field
                onFocus={() => setFocus(true)}
                type={props.type}
                min={props.min}
                max={props.max}
                name={props.name}
                placeholder={props.placeholder}
                style={styles.field}
                disabled={props.disabled}
            />
            {props.withClick ? (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {props.withClick}
                </div>
            ) : null}
        </div>
    );
}

const makeStyles = (props, touched, errors, focus) => ({
    container: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
    },
    field: {
        textAlign: 'center',
        width: 200,
        backgroundColor: 'transparent',
        outline: 'none',
        border: 'none',
        borderBottom: `1px solid ${theme.colors.form.gradient03}`,
        padding: 5,
        fontSize: theme.font.size.M,
        ...(props.style ? props.style : {}),
        ...(!props.disabled && focus
            ? {
                  outline: 'none',
                  border: 'none',
                  borderBottom: `2px solid ${theme.colors.primary}`,
              }
            : {}),
        ...(touched[props.name] && errors[props.name]
            ? { borderBottom: '1px solid red' }
            : {}),
    },
    fieldFocus: {
        outline: 'none',
        border: 'none',
        borderBottom: `2px solid ${theme.colors.primary}`,
    },
});
