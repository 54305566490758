import React from 'react';
import Radio from './index';

export default function RadioContainer(props) {
    return (
        <div style={styles.radioContainer}>
            {props.data.map(item => (
                <Radio
                    noIcon={props.noIcon}
                    value={item}
                    key={item}
                    name={props.name}
                    size={props.itemSize}
                />
            ))}
        </div>
    );
}

const styles = {
    radioContainer: {
        display: 'flex',
    },
};
