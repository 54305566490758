import React from 'react';
import RadioNumberComp from './RadioNumbersComp';
import theme from 'theme';

export default function RadioNumbersContainer(props) {
    return (
        <div style={styles.container}>
            {[
                { value: '1', name: 'resident' },
                { value: '2', name: 'resident' },
                { value: '3', name: 'resident' },
                { value: '4', name: 'resident' },
                { value: '5', name: 'resident', wideIcon: true },
            ].map(radio => (
                <RadioNumberComp key={radio.value} {...radio} />
            ))}
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    radio: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    label: {
        height: '100%',
        width: '100%',
        fontSize: theme.font.size.M,
        textAlignLast: 'center',
        textAlign: 'center',
    },
};
