import React from 'react';
import theme from 'theme';

export default function Dot(props) {
    return (
        <div
            onClick={props.onClick}
            style={{
                ...styles.dot,
                backgroundColor: props.isActive
                    ? theme.colors.primary
                    : theme.colors.form.gradient02,
            }}
        />
    );
}

const styles = {
    dot: {
        height: 20,
        width: 20,
        borderRadius: '50%',
        margin: '0 10px',
    },
};
