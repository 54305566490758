import React from 'react';
import theme from 'theme';

const CheckboxSVG = props => (
    <svg
        width={15}
        height={15}
        style={props.checked ? { backgroundColor: theme.colors.primary } : {}}
        viewBox="0 0 24 24"
        {...props}
    >
        {props.checked ? (
            <path
                fill={theme.colors.white}
                d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24zm-5.541 8.409l-1.422-1.409-7.021 7.183-3.08-2.937-1.395 1.435 4.5 4.319 8.418-8.591z"
            />
        ) : (
            <path
                fill={theme.colors.primary}
                d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z"
            />
        )}
    </svg>
);

export default CheckboxSVG;
