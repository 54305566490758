import ButtonComp from 'components/ButtonComp';
import t from 'utils/Translator';
import Input from './Input';
import Privacy from './Privacy';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { sendFormGA } from 'utils/googleAnalytics';

export default function Form() {
    const [checked, setChecked] = useState(false);
    const { handleSubmit, isSubmitting } = useFormikContext();

    const handleButton = () => {
        handleSubmit();
        sendFormGA();
    };

    return (
        <div style={styles.container} className="col-xs-12 col-md-6">
            {['firstName', 'lastName', 'phone', 'email'].map(item => (
                <Input key={item} name={item} />
            ))}
            <div style={styles.buttonContainer}>
                <ButtonComp
                    style={
                        isSubmitting || !checked
                            ? { display: 'none' }
                            : { display: 'flex' }
                    }
                    onClick={handleButton}
                    label={t.get('placeholders.submit')}
                />
            </div>
            <Privacy
                checked={checked}
                setChecked={() => setChecked(!checked)}
            />
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 15,
    },
    buttonContainer: {
        transition: 'all 2.5s',

        alignSelf: 'center',
    },
};
