import theme from 'theme';
import t from 'utils/Translator';
import Form from './components/Form';
import Contact from './components/Contact';
import { useEffect } from 'react';
import { step3GA } from 'utils/googleAnalytics';

export default function Step3() {
    useEffect(() => {
        step3GA();
    }, []);

    return (
        <div className="row container-fluid" style={styles.container}>
            <div style={styles.title} className="col-xs-12">
                <div>{t.get('titles.personalData.1')}</div>
                <div>{t.get('titles.personalData.2')}</div>
            </div>
            <div className="row">
                <Form />
                <Contact />
            </div>
        </div>
    );
}

const styles = {
    container: {
        backgroundColor: theme.colors.form.gradient01,
        margin: 0,
        paddingBottom: 32,
    },
    title: {
        padding: 32,
        fontSize: 20,
        textAlign: 'center',
    },
};
