import { envHasGA } from 'utils';
import ReactGA from 'react-ga';

export const step1GA = () => {
    if (envHasGA()) {
        ReactGA.event({
            category: 'etape',
            action: 'vue',
            label: 'logement',
        });
    }
};
export const step2GA = () => {
    if (envHasGA()) {
        ReactGA.event({
            category: 'etape',
            action: 'vue',
            label: 'installation',
        });
    }
};
export const step3GA = () => {
    if (envHasGA()) {
        ReactGA.event({
            category: 'etape',
            action: 'vue',
            label: 'projet',
        });
    }
};
export const sendFormGA = () => {
    if (envHasGA()) {
        ReactGA.event({
            category: 'bouton',
            action: 'clique',
            label: 'pdf',
        });
    }
};
