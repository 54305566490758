import { useEffect, useState } from 'react';
import useWindowSize from '../../../data/common/hooks/useWindowSize';
import RadioContainer from 'components/Radio/RadioContainer';
import InputComp from 'components/input/inputComp';
import t from 'utils/Translator';
import ContainerLarge from 'components/Increment/ContainerLarge';
import CheckboxContainer from 'components/Checkbox/CheckboxContainer';
import theme from 'theme';
import ButtonComp from 'components/ButtonComp';
import { fetchBill, fetchEstimation } from 'utils/fetch';
import { useField, useFormikContext } from 'formik';
import DunnoCheckbox from './components/DunnoCheckbox';
import { step2GA } from 'utils/googleAnalytics';

export default function Step2(props) {
    const { values } = useFormikContext();
    const { width } = useWindowSize();
    const styles = makeStyles(values, width);
    const [, , billHelpers] = useField('bill');
    const [, , electricPanelsHelpers] = useField('electricPanels');

    useEffect(() => {
        step2GA();
    }, []);

    useEffect(() => {
        if (values.electricPanelsCheckbox) {
            electricPanelsHelpers.setValue(1);
        }
    }, [values.electricPanelsCheckbox]);

    useEffect(() => {
        if (values.billCheckbox) {
            getBill();
        }
    }, [values]);
    const getBill = () => {
        fetchBill(values).then(data => {
            billHelpers.setValue(Math.round(data));
        });
    };

    const goToNextStep = () => {
        fetchEstimation(values).then(data => {
            props.handleEstimation(data);
            props.nextStep();
        });
    };

    return (
        <>
            <div className="row">
                <div style={styles.provider} className="col-xs-12 col-md-5">
                    <div style={styles.title}>{t.get('titles.provider')}</div>
                    <RadioContainer data={['edf', 'other']} name="provider" />
                </div>
                <div style={styles.heating} className="col-xs-12 col-md-7">
                    <div style={styles.title}>{t.get('titles.heating')}</div>
                    <CheckboxContainer
                        data={[
                            'electricHeater',
                            'airConditioner',
                            'pac',
                            'fossilFuel',
                        ]}
                        name="heating"
                    />
                </div>
                <div style={styles.hotWater} className="col-xs-12 col-md-7">
                    <div style={styles.title}>{t.get('titles.hotWater')}</div>
                    <RadioContainer
                        data={[
                            'thermodynamic',
                            'waterTank',
                            'fossilFuel',
                            'other',
                        ]}
                        name="hotWater"
                    />
                </div>
                <div style={styles.subContainer} className="col-xs-12 col-md-5">
                    <div style={styles.electricPanels}>
                        <div style={styles.subContainerTitle}>
                            {t.get('titles.electricPanels')}
                        </div>
                        <InputComp
                            min={0}
                            type="number"
                            name="electricPanels"
                            placeholder={t.get('placeholders.electricPanels')}
                            withClick={
                                <DunnoCheckbox
                                    name={'electricPanelsCheckbox'}
                                />
                            }
                            style={{ width: 150 }}
                            disabled={values.electricPanelsCheckbox}
                        />
                    </div>
                    <div style={styles.bill}>
                        <div style={styles.subContainerTitle}>
                            {t.get('titles.bill')}
                        </div>
                        <InputComp
                            min={0}
                            type="number"
                            name="bill"
                            placeholder={t.get('placeholders.bill')}
                            withClick={<DunnoCheckbox name={'billCheckbox'} />}
                            style={{ width: 150 }}
                            disabled={values.billCheckbox}
                        />
                    </div>
                </div>
                <div style={styles.other} className="col-xs-12">
                    <div style={styles.title}>{t.get('titles.other')}</div>
                    <ContainerLarge
                        data={[
                            'fridge',
                            'tv',
                            'washingMachine',
                            'dishWasher',
                            'dryer',
                            'swimmingPool',
                            'swimmingPoolPac',
                            'electricCar',
                        ]}
                        itemSize="small"
                    />
                </div>
            </div>
            <div style={styles.buttonContainer}>
                <ButtonComp
                    style={
                        values.bill ? { display: 'flex' } : { display: 'none' }
                    }
                    label={t.get('placeholders.next')}
                    onClick={() => goToNextStep()}
                />
            </div>
        </>
    );
}

const makeStyles = (values, width) => ({
    title: {
        padding: '10px 0',
        textAlign: 'center',
        width: '100%',
    },
    provider: {
        ...theme.active(values.provider),
        backgroundColor: theme.colors.white,
    },
    heating: {
        ...theme.active(values.heating),
        ...theme.disabling(values.provider),
        backgroundColor: theme.colors.form.gradient01,
    },
    hotWater: {
        ...theme.active(values.hotWater),
        ...theme.disabling(values.heating && values.heating.length > 0),
        backgroundColor: theme.colors.form.gradient02,
    },
    subContainer: {
        padding: 0,
        backgroundColor: theme.colors.white,
        display: 'grid',
        height: 140,
    },
    subContainerTitle: {
        margin: '5px 0',
        textAlign: 'center',
        width: '100%',
    },
    electricPanels: {
        ...theme.active(values.electricPanels),
        ...theme.disabling(values.hotWater && values.heating && values.heating.length > 0),
        backgroundColor: theme.colors.white,
    },
    bill: {
        ...theme.active(values.bill),
        ...theme.disabling(values.electricPanels && values.heating && values.heating.length > 0),
        backgroundColor: theme.colors.form.gradient01,
    },
    other: {
        ...theme.disabling(values.billCheckbox && values.heating && values.heating.length > 0),
        backgroundColor: theme.colors.white,
    },
    buttonContainer: {
        marginTop: 10,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
});
