import config from 'config';

export const getEnv = () => config.env;
export const getRGBPDF = () => config.rgbPDF;
export const getEnvValue = key => config[key];
export const envHasHotjar = () => {
    const hotjar = getEnvValue('hotjar');
    if (hotjar) {
        return !!hotjar.hjid && !!hotjar.hjsv;
    }
    return false;
};
export const envHasGA = () => !!getEnvValue('googleAnalyticId');
