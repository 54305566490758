import React from 'react';
import Dot from './dot';
import t from 'utils/Translator';
import theme from 'config/theme';

export default function Dots(props) {
    const handlePreviousStep = () => {
        if (props.currentStep > 1) {
            props.setStep(props.currentStep - 1);
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.previousContainer}>
                {props.currentStep === 2 || props.currentStep === 3 ? (
                    <div onClick={handlePreviousStep} style={styles.previous}>
                        {t.get('placeholders.previous')}
                    </div>
                ) : null}
            </div>

            <div style={styles.dotsContainer}>
                <Dot
                    isActive={
                        props.currentStep === 1 || props.currentStep === 4
                    }
                    onClick={() =>
                        process.env.NODE_ENV === 'development' &&
                        props.setStep(1)
                    }
                />
                <Dot
                    isActive={
                        props.currentStep === 2 || props.currentStep === 4
                    }
                    onClick={() =>
                        process.env.NODE_ENV === 'development' &&
                        props.setStep(2)
                    }
                />
                <Dot
                    isActive={
                        props.currentStep === 3 || props.currentStep === 4
                    }
                    onClick={() =>
                        process.env.NODE_ENV === 'development' &&
                        props.setStep(3)
                    }
                />
            </div>
            <div style={styles.previousContainer} />
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 20,
        padding: '30px 0',
    },
    dotsContainer: {
        display: 'flex',
    },
    previousContainer: {
        width: '100%',
    },
    previous: {
        left: 20,
        color: theme.colors.primary,
        cursor: 'pointer',
        userSelect: 'none',
    },
};
