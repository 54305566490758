import { useRef, useState, useEffect } from 'react';
import Roofing from '../Roofing';
import Collective from './Collective';
import Individual from './Individual';
import { useFormikContext } from 'formik';
import { step1GA } from 'utils/googleAnalytics';

function Step1(props) {
    const [mapIsInit, setMapIsInit] = useState(false);
    const { values } = useFormikContext();
    const mapInstanceRef = useRef();
    const mapApiRef = useRef();

    useEffect(() => {
        step1GA();
        if (props.googlePlace.current) {
            moveToCurrentPositionAfterPrevious();
        }
    }, []);

    const moveToCurrentPositionAfterPrevious = () => {
        if (!mapApiRef.current) {
            setTimeout(moveToCurrentPositionAfterPrevious);
            return;
        }
        moveToCurrentPosition(props.googlePlace.current);
    };

    const moveToCurrentPosition = (pos, zoom = 19) => {
        mapInstanceRef.current.setCenter(pos);
        mapInstanceRef.current.setZoom(zoom);
    };

    const childProps = {
        mapInstanceRef,
        mapApiRef,
        mapIsInit,
        moveToCurrentPosition,
        googlePlace: props.googlePlace,
    };

    const renderStep = () => {
        switch (values.buildingType) {
            case 'individual':
                return <Individual {...childProps} />;
            case 'collective':
                return <Collective {...childProps} />;
            default:
                return <Individual {...childProps} />;
        }
    };

    return (
        <>
            {renderStep()}
            <Roofing
                mapInstanceRef={mapInstanceRef}
                mapApiRef={mapApiRef}
                setMapIsInit={setMapIsInit}
                mapIsInit={mapIsInit}
                nextStep={props.nextStep}
                googlePlace={props.googlePlace}
                polygonPos={props.polygonPos}
            />
        </>
    );
}

export default Step1;
