import React from 'react';
import IconContainer from '../IconContainer';
import { Field, useFormikContext, useField } from 'formik';
import t from 'utils/Translator';
import theme from 'theme';
import Text from 'components/Text';

function Radio({ name, value, size, noIcon }) {
    const [field] = useField({ name, value, type: 'radio' });
    const { resetForm } = useFormikContext();

    const handleChange = e => {
        if (e.target.name === 'buildingType') {
            resetForm();
        }
        field.onChange(e);
    };

    return (
        <div style={styles.container}>
            <label>
                <Field
                    onChange={handleChange}
                    name={name}
                    value={value}
                    type="radio"
                    style={{ display: 'none' }}
                />
                {noIcon ? (
                    <Text label={value} selected={field.checked} />
                ) : (
                    <IconContainer
                        selected={field.checked}
                        SVGName={value}
                        size={size}
                    />
                )}
            </label>
            {noIcon ? null : (
                <div style={styles.label}>{t.get(`labels.${value}`)}</div>
            )}
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        margin: '0 2px',
    },
    label: {
        paddingTop: 4,
        height: '100%',
        width: '90%',
        fontSize: theme.font.size.S,
        textAlignLast: 'center',
        textAlign: 'center',
    },
};

export default Radio;
