import t from 'utils/Translator';
import { getEnvValue } from 'utils';

export default function Privacy(props) {
    const legalNotice = getEnvValue('legal');
    const privacyNotice = getEnvValue('privacy');

    const renderKnowMore = () => {
        if (!privacyNotice && !legalNotice) return null;
        return (
            <>
                {t.get('privacy.more')}
            </>
        );
    }

    const renderPrivacy = () => {
        if (!privacyNotice) return null;
        return (
            <>
                {t.get('privacy.page')}
                <a
                    href={getEnvValue('privacy')}
                    target="_blank"
                    rel="noreferrer"
                >
                    {t.get('privacy.privacy')}
                </a>
            </>
        );
    };

    const renderAnd = () => {
        if (!privacyNotice || !legalNotice) return null;
        return (
            <>
                {t.get('privacy.and')}
            </>
        );
    };

    const renderLegal = () => {
        if (!legalNotice) return null;
        return (
            <>
                {t.get('privacy.page')}
                <a href={legalNotice} target="_blank" rel="noreferrer">
                    {t.get('privacy.legal')}
                </a>
            </>
        );
    };

    return (
        <div style={styles.container}>
            <input
                type="checkbox"
                checked={props.checked}
                onChange={() => props.setChecked()}
            />
            {t.get('privacy.main')} <br />
            {renderKnowMore()}
            {renderPrivacy()}
            {renderAnd()}
            {renderLegal()}
        </div>
    );
}

const styles = {
    container: {
        padding: '10',
        marginTop: 15,
    },
};
