import theme from 'theme';
import t from 'utils/Translator';
import { getEnvValue } from 'utils';
import { ReactComponent as Info } from '../../../../data/assets/svgs/info.svg';

export default function Contact() {
    const href = `mailto:${getEnvValue('mail')}`;

    const renderInfos = () => {
        const keys = Object.keys(getEnvValue('custom'));
        return keys.map(renderInfo);
    };

    const renderInfo = key => {
        return (
            <div key={key}>
                {getEnvValue('custom')[key]} <br />
            </div>
        );
    };

    const renderMail = () => {
        if (!getEnvValue('mail')) return null;
        return (
            <a href={href} target="_blank" rel="noreferrer">
                {getEnvValue('mail')}
            </a>
        );
    };

    return (
        <div style={styles.container} className="col-xs-12 col-md-6">
            <div style={styles.title}>
                <Info style={styles.info} />
                {t.get('contact.title')}
            </div>
            <div style={styles.text}>{t.get('contact.content')}</div>
            <div style={{ alignSelf: 'center' }}>
                <div style={styles.text}>{t.get('contact.more')}</div>
                <strong>{getEnvValue('name')}</strong> <br />
                {renderInfos()}
                {renderMail()}
            </div>
        </div>
    );
}

const styles = {
    container: {
        backgroundColor: theme.colors.form.gradient02,
        padding: 20,
    },
    info: { marginRight: 5, height: 20 },
    title: {
        display: 'flex',
        fontWeight: 'bold',
        marginBottom: 20,
    },
    text: { marginBottom: 10 },
};
