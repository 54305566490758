import theme from 'theme';
import t from 'utils/Translator';

export default function Text(props) {
    const styles = makeStyles(props);
    return <div style={styles.container}>{t.get(`labels.${props.label}`)}</div>;
}

const makeStyles = props => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: props.selected
            ? theme.colors.primary
            : theme.colors.form.gradient03,
        fontSize: 13,
        backgroundColor: props.selected ? theme.colors.white : 'transparent',
        height: 70,
        width: 70,
        borderRadius: 3,
        border: `1px solid ${
            props.selected ? theme.colors.primary : theme.colors.form.gradient03
        }`,
        boxShadow: props.selected
            ? '2px 2px 5px 0px rgba(189,179,189,1)'
            : 'none',
        boxSizing: 'border-box',
        pointerEvents: 'none',
    },
});
