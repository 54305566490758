import RadioContainer from 'components/Radio/RadioContainer';
import InputComp from 'components/input/inputComp';
import RadioNumbersContainer from 'components/radioNumbers/RadioNumbersContainer';
import t from 'utils/Translator';
import theme from 'theme';
import AddressInput from 'components/AddressInput';
import { useFormikContext } from 'formik';
import React from 'react';
import { ReactComponent as Info } from '../../../../data/assets/svgs/info.svg';
import ReactTooltip from 'react-tooltip';

export default function Individual(props) {
    const { values } = useFormikContext();
    const styles = makeStyles(values);
    return (
        <>
            <div className="row" style={styles.container}>
                <div className="col-xs-12 col-md-5" style={styles.buildingType}>
                    <div style={styles.title}>
                        {t.get('titles.buildingType')}
                    </div>
                    <RadioContainer
                        data={['individual', 'apartment'/*, 'collective'*/]}
                        name="buildingType"
                    />
                </div>
                <div className="col-xs-12 col-md-4" style={styles.situation}>
                    <div style={styles.title}>{t.get('titles.situation')}</div>
                    <RadioContainer
                        data={['owner', 'tenant']}
                        name="situation"
                    />
                </div>
                <div
                    className="col-xs-12 col-md-3"
                    style={styles.subContainer01}
                >
                    <div style={styles.year} className="row">
                        <div style={styles.info}>
                            <Info />
                        </div>
                        <div
                            data-for="tooltipYear"
                            data-tip="Pour un projet de construction, vous pouvez saisir une année future"
                            style={styles.title}
                        >
                            {t.get('titles.year')}
                        </div>
                        <ReactTooltip
                            id="tooltipYear"
                            place="top"
                            type="info"
                            effect="solid"
                            backgroundColor={theme.colors.primary}
                            delayHide={1000}
                            multiline={true}
                        />
                        <InputComp
                            type="number"
                            min="1800"
                            max={new Date().getFullYear()}
                            name="year"
                            placeholder={t.get('placeholders.year')}
                            style={{ width: 150 }}
                        />
                    </div>
                    <div style={styles.livingArea} className="row">
                        <div style={styles.title}>{t.get('titles.area')}</div>
                        <InputComp
                            min={0}
                            type="number"
                            name="livingArea"
                            placeholder={t.get('placeholders.area')}
                            style={{ width: 150 }}
                        />
                    </div>
                </div>
                <div className="col-xs-12 col-md-5" style={styles.resident}>
                    <div style={styles.title}>{t.get('titles.resident')}</div>
                    <RadioNumbersContainer />
                </div>
                <div
                    className="col-xs-12 col-md-7"
                    style={styles.subContainer02}
                >
                    <div style={styles.title}>{t.get('titles.address')}</div>
                    <div style={styles.postalCode}>
                        <InputComp
                            name="postalCode"
                            placeholder={t.get('placeholders.postalCode')}
                            style={{ width: '70%' }}
                        />
                    </div>
                    <div style={styles.address}>
                        <AddressInput
                            googlePlace={props.googlePlace}
                            mapIsInit={props.mapIsInit}
                            mapInstanceRef={props.mapInstanceRef}
                            mapApiRef={props.mapApiRef}
                            moveToCurrentPosition={props.moveToCurrentPosition}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

const makeStyles = values => {
    return {
        title: {
            padding: '10px 0',
            textAlign: 'center',
            width: '100%',
            zIndex: 5,
        },
        container: {
            width: '100%',
        },
        buildingType: {
            ...theme.active(values.buildingType),
            backgroundColor: theme.colors.white,
            paddingBottom: 5,
        },
        situation: {
            ...theme.active(values.situation),
            ...theme.disabling(
                values.buildingType === 'apartment' ? '' : values.buildingType,
            ),
            backgroundColor: theme.colors.form.gradient02,
            paddingBottom: 5,
        },
        subContainer01: {
            ...theme.active(values.year && values.livingArea),
            ...theme.disabling(
                values.situation === 'tenant' ? '' : values.situation,
            ),
            backgroundColor: theme.colors.white,
        },
        year: {
            position: 'relative',
            backgroundColor: theme.colors.white,
            paddingBottom: 12,
        },
        livingArea: {
            backgroundColor: theme.colors.form.gradient01,
            paddingBottom: 12,
        },
        resident: {
            ...theme.active(values.resident),
            ...theme.disabling(values.livingArea),
            backgroundColor: theme.colors.form.gradient01,
            paddingBottom: 10,
        },
        subContainer02: {
            ...theme.active(values.address && values.postalCode),
            ...theme.disabling(values.resident),
            backgroundColor: theme.colors.white,
            display: 'grid',
            paddingBottom: 10,
        },
        postalCode: { backgroundColor: theme.colors.white, paddingBottom: 5 },
        address: {
            backgroundColor: theme.colors.white,
        },
        info: {
            width: 12,
            height: 12,
            position: 'absolute',
            right: 10,
            top: 5,
        },
    };
};
