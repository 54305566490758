const Translator = require('../../../../../../src/utils/js-translator');

const t = new Translator();

const supportedLocales = {
    fr: {
        file: require('../../../../../../langs/fr/translations.json'),
        moment: 'fr',
    },
};

export const translateError = (path, params) =>
    t.get(path, params, 'general.error');

export const setLocale = locale => {
    if (isSupportedLocale(locale)) {
        t.setLocale(locale);
        t.load(locale, supportedLocales[locale].file);
    }
};

/** Init Locale */
const isSupportedLocale = locale =>
    Object.keys(supportedLocales).find(
        supportedLocale => supportedLocale === locale,
    );

t.setLocale('fr');
t.load('fr', supportedLocales.fr.file);

export default t;
