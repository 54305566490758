import * as Yup from 'yup';
import t from 'utils/Translator';

export const schema = Yup.object().shape({
    year: Yup.number()
        .integer()
        .moreThan(1800, t.get('errors.min'))
        .lessThan(new Date().getFullYear() + 100, t.get('errors.max'))
        .required(t.get('errors.required')),
    livingArea: Yup.number()
        .moreThan(0, t.get('errors.min'))
        .required(t.get('errors.required')),
    resident: Yup.number()
        .integer()
        .moreThan(0, t.get('errors.min'))
        .required(t.get('errors.required')),
    postalCode: Yup.string()
        .matches(/\d{5}/)
        .required(t.get('errors.required')),
    address: Yup.string()
        .min(2, t.get('errors.min'))
        .max(100, t.get('errors.max'))
        .required(t.get('errors.required')),
    electricPanels: Yup.number()
        .moreThan(0, t.get('errors.min'))
        .required(t.get('errors.required')),
    bill: Yup.number()
        .moreThan(0, t.get('errors.min'))
        .required(t.get('errors.required')),
    firstName: Yup.string()
        .min(2, t.get('errors.min'))
        .max(50, t.get('errors.max'))
        .required(t.get('errors.required')),
    lastName: Yup.string()
        .min(2, t.get('errors.min'))
        .max(50, t.get('errors.max'))
        .required(t.get('errors.required')),
    phone: Yup.string()
        .min(2, t.get('errors.min'))
        .max(50, t.get('errors.max'))
        .required(t.get('errors.required')),
    email: Yup.string()
        .email(t.get('errors.email'))
        .required(t.get('errors.required')),
});
