import React from 'react';
import RadioContainer from 'components/Radio/RadioContainer';
import InputComp from 'components/input/inputComp';
import t from 'utils/Translator';
import theme from 'theme';
import AddressInput from 'components/AddressInput';
import { useFormikContext } from 'formik';

export default function Collective(props) {
    const { values } = useFormikContext();
    const styles = makeStyles(values);
    return (
        <div className="row">
            <div style={styles.buildingType} className="col-xs-12 col-md-5">
                <div style={styles.title}>{t.get('titles.buildingType')}</div>
                <RadioContainer
                    data={['individual', 'apartment', 'collective']}
                    name="buildingType"
                />
            </div>
            <div style={styles.situation} className="col-xs-12 col-md-7">
                <div style={styles.title}>{t.get('titles.situation')}</div>
                <RadioContainer
                    data={['residence', 'hotel', 'trade']}
                    name="situation"
                    itemSize="large"
                />
            </div>
            <div style={styles.autoConsumption} className="col-xs-12 col-md-7">
                <div style={styles.title}>
                    {t.get('titles.autoConsumption')}
                </div>
                <RadioContainer
                    data={['self', 'partial', 'sell']}
                    name="autoConsumption"
                    itemSize="large"
                />
            </div>
            <div style={styles.subContainer01} className="col-xs-12 col-md-5">
                <div style={styles.year}>
                    <div style={styles.title}>{t.get('titles.year')}</div>
                    <InputComp
                        min={1800}
                        type="number"
                        name="year"
                        placeholder={t.get('placeholders.year')}
                        style={{ width: 150 }}
                    />
                </div>
                <div style={styles.livingArea}>
                    <div style={styles.title}>{t.get('titles.area')}</div>
                    <InputComp
                        min={0}
                        type="number"
                        name="livingArea"
                        placeholder={t.get('placeholders.area')}
                        style={{ width: 150 }}
                    />
                </div>
            </div>
            <div style={styles.resident} className="col-xs-12 col-md-6">
                <div style={styles.title}>{t.get('titles.resident')}</div>
                <InputComp
                    name="resident"
                    placeholder="Nombre d'occupant"
                    min={0}
                />
            </div>
            <div style={styles.subContainer02} className="col-xs-12 col-md-6">
                <div style={styles.postalCode}>
                    <div style={styles.title}>{t.get('titles.address')}</div>
                    <InputComp
                        name="postalCode"
                        placeholder={t.get('placeholders.postalCode')}
                        style={{ width: '70%' }}
                    />
                </div>
                <div style={styles.address}>
                    <AddressInput
                        googlePlace={props.googlePlace}
                        mapIsInit={props.mapIsInit}
                        mapInstanceRef={props.mapInstanceRef}
                        mapApiRef={props.mapApiRef}
                        moveToCurrentPosition={props.moveToCurrentPosition}
                    />
                </div>
            </div>
        </div>
    );
}

const makeStyles = values => ({
    title: {
        padding: '10px 0',
        textAlign: 'center',
        width: '100%',
    },
    buildingType: {
        ...theme.active(values.buildingType),
        backgroundColor: theme.colors.white,
        paddingBottom: 5,
    },
    situation: {
        ...theme.active(values.situation),
        ...theme.disabling(values.buildingType),
        backgroundColor: theme.colors.form.gradient01,
        paddingBottom: 5,
    },
    autoConsumption: {
        ...theme.active(values.autoConsumption),
        ...theme.disabling(values.situation),
        backgroundColor: theme.colors.form.gradient02,
        paddingBottom: 5,
    },
    subContainer01: {
        ...theme.active(values.year && values.livingArea),
        ...theme.disabling(values.autoConsumption),
        backgroundColor: theme.colors.white,
        display: 'grid',
        padding: 0,
    },
    year: {
        backgroundColor: theme.colors.white,
        paddingBottom: 5,
    },
    livingArea: {
        backgroundColor: theme.colors.form.gradient01,
        paddingBottom: 5,
    },
    resident: {
        ...theme.active(values.buildingType),
        ...theme.disabling(values.livingArea),
        backgroundColor: theme.colors.form.gradient01,
        paddingBottom: 5,
    },
    subContainer02: {
        ...theme.active(values.address && values.postalCode),
        ...theme.disabling(values.resident),
        backgroundColor: theme.colors.white,
        display: 'grid',
        paddingBottom: 10,
    },
    address: {
        backgroundColor: theme.colors.white,
    },
    postalCode: {
        backgroundColor: theme.colors.white,
    },
});
